<template>
  <div>
    <span style="text-align:center;width:10%;min-width:2rem;float:left" >{{ staggeredDiscount + "%" }}</span><span style="text-align: right;width:auto;float:right">{{ "- " + staggeredDiscountAmount  }}</span>
  </div>
</template>

<script>
import ShipmentDates from "@/FrontEnd/models/ShipmentDates";

export default {
  name: "OrderSheetShipmentStaggeredDiscount",
  props: {
    discountScale: Array,
    shipmentId : Number,
    shipments: {
      type: ShipmentDates
    }

  },
  computed:{
    discountPerShipment() {
      // Platzhalter für zukünftige globale Konfiguration für den Discount pro Lieferdatum
      return false;
    },
    shipmentSum(){
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      return shipment.totalAmount;
    },
    shipmentQuantity(){
      let shipment = this.shipments.getShipmentById(this.shipmentId);

      return shipment.totalQuantity;
    },

    formatter(){
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol'})
    },
    staggeredDiscountAmount(){
        let shipment = this.shipments.getShipmentById(this.shipmentId);
        shipment.staggeredDiscount = this.shipmentSum * (this.staggeredDiscount/100);
        return this.formatPrice(shipment.staggeredDiscount );
    },
    staggeredDiscount(){
      if (this.discountPerShipment) {
        console.log("Berechne Rabatt pro Lieferdatum");
        // Bestehende Einzelshipment-Logik:

        let discounts = JSON.parse(JSON.stringify(this.discountScale));
        let shipment = this.shipments.getShipmentById(this.shipmentId);

        let type = discounts[0].type;
        if (type === 'quantity') {
          for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
            if (shipment.totalQuantity >= discount.min) {
              return discount.percent;
            }
          }
        } else {
          for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
            if (shipment.totalAmount >= discount.min) {
              return discount.percent;
            }
          }
        }

        return 0;
      }
      else {
        console.log("Berechne Gesamtrabatt");
        // Neue Gesamtrabatt-Logik:
        let discounts = JSON.parse(JSON.stringify(this.discountScale));
        let totalAmount = 0;
        let type = discounts[0].type;
        // Gesamtsumme aller Shipments ermitteln
        for (let shipment of this.shipments) {
          totalAmount += shipment.totalAmount;
        }
        let applicablePercent = 0;
        if(type === 'quantity'){
          let totalQuantity = 0;
          for (let shipment of this.shipments) {
            totalQuantity += shipment.totalQuantity;
          }
          for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
            if (totalQuantity >= discount.min) {
              applicablePercent = discount.percent;
              break;
            }
          }
        } else {
          for (let discount of discounts.sort((a, b) => (b.min > a.min ? 1 : -1))) {
            if (totalAmount >= discount.min) {
              applicablePercent = discount.percent;
              break;
            }
          }
        }
        return applicablePercent;
      }
    }
  },
  methods:{
    formatPrice(price){
      return this.formatter.format(price);
    },
  }
}
</script>

<style scoped>

</style>