<template xmlns="http://www.w3.org/1999/html">
    <page-with-navigation>
        <div class="form-wrapper" >
            <button @click="toggleBaseData" class="slim">
                <span v-if="baseDataHidden">{{ $t('orderSeasonForm.show') }}</span>
                <span v-else>{{ $t('orderSeasonForm.hide') }}</span>
            </button>
            <fieldset class="form" v-show="!baseDataHidden">
                <div :class="{'error-input': nameMissing}">
                    <label :for="'name-'+_uid">{{ $t('orderSeasonForm.name') }}</label>
                    <input :id="'name-'+_uid" type="text" v-model="name" />
                    <span v-if="nameMissing" class="error-msg">{{ $t('orderSeasonForm.nameMissing') }}</span>
                </div>

                <div style="width:auto;min-width:19.5rem">
                  <label :for="'season-start-date-'+_uid">{{ $t('orderSeasonForm.seasonStartDate') }}</label>
                  <datepicker
                      :language="de"
                      monday-first
                      v-model="startDate"
                      :id="'season-start-date-'+_uid"
                      :format="formatDate"
                  ></datepicker>
                </div>
                <div style="width:auto;min-width:19.5rem">
                  <label :for="'season-end-date-'+_uid">{{ $t('orderSeasonForm.seasonEndDate') }}</label>
                  <datepicker
                      :language="de"
                      monday-first
                      v-model="endDate"
                      :id="'season-end-date-'+_uid"
                      :format="formatDate"
                  ></datepicker>
                </div>
            </fieldset>
            <fieldset class="order-season-modes" v-show="!baseDataHidden">
              <h4>{{ $t('orderSeasonForm.orderMode.title') }}</h4>
              <div >
                <div>
                  <input style="width:auto;" type="radio" id="order-season-mode-single-order" value="singleOrder" v-model="orderMode">
                  <label for="order-season-mode-single-order">{{ $t('orderSeasonForm.orderMode.singleOrder.title')+ " " + $t('orderSeasonForm.orderMode.singleOrder.description') }}</label>
                </div>
                <div>
                  <input style="width:auto;" type="radio" id="order-season-mode-multi-order-brands" value="multiOrderBrands" v-model="orderMode">
                  <label for="order-season-mode-multi-order-brands">{{ $t('orderSeasonForm.orderMode.multiOrder.brands.title')+ " " + $t('orderSeasonForm.orderMode.multiOrder.brands.description') }}</label>
                </div>
              </div>
            </fieldset>
            <fieldset class="order-season-modes" v-show="!baseDataHidden">
              <h4>{{ $t('orderSeasonForm.seasonMinOrderAmount') }}</h4>
              <input id="'season-min-order-amount-per-shipment'+_uid" type="checkbox" v-model="minOrderAmount.perShipment">
              <label for="'season-min-order-amount-per-shipment'+_uid">{{ $t('orderSeasonForm.seasonMinOderAmountPerShipment')}}</label>
              <div >
                <input style="width:auto;min-width:9.5rem" :id="'season-min-order-amount-'+_uid" type="number" value="0" min="0.00" max="10000.00" step="0.01" v-model="minOrderAmount.minAmount">
              </div>
            </fieldset>


            <fieldset class="order-season-periods" v-show="!baseDataHidden">
                <h4>{{ $t('orderSeasonForm.deadlines') }}</h4>
                <!--<h4>{{ $t('orderSeasonForm.periodTitle') }}</h4>
                <div>
                    <label :for="'use-periods-'+_uid">{{ $t('orderSeasonForm.usePeriods') }}</label>
                    <input :id="'use-periods-'+_uid" type="checkbox" v-model="usePeriods">
                </div>
                <div>
                  <label :for="'allow-deadline-selection-'+_uid">{{ $t('orderSeasonForm.allowDeadlineSelection') }}</label>
                  <input :id="'allow-deadline-selection-'+_uid" type="checkbox" v-model="allowDeadlineSelection">
                </div>-->
                <advance-order-deadline-editor v-show="!baseDataHidden" v-model="deadlines"></advance-order-deadline-editor>
            </fieldset>
            <fieldset class="order-season-discounts" v-show="!baseDataHidden">
              <h4>{{ $t('orderSeasonForm.discountsTitle') }}</h4>
              <h3>{{ $t('orderSeasonForm.staggeredDiscountTitle') }}</h3>
              <div>
                  <div>
                    <input style="width:auto;" type="radio" id="discount-type-selection-amount" value="amount" v-model="discountType">
                    <label for="discount-type-selection-amount">{{ $t('orderSeasonForm.discountTypes.amount') }}</label>
                  </div>
                  <div>
                    <input style="width:auto;" type="radio" id="discount-type-selection-quantity" value="quantity" v-model="discountType">
                    <label for="discount-type-selection-quantity">{{ $t('orderSeasonForm.discountTypes.quantity') }}</label>
                  </div>
              </div>
              <discount-scale-amount v-if="discountType === 'amount'" v-model="discountScale"></discount-scale-amount>
              <discount-scale-quantity v-if="discountType === 'quantity'" v-model="discountScale"></discount-scale-quantity>

              <h3>{{ $t('orderSeasonForm.shipmentDiscount.title') }}</h3>
              <div>
                <input id="shipment-discount-active" type="checkbox" v-model="shipmentDiscount.status">
                <label for="shipment-discount-active">{{ $t('orderSeasonForm.shipmentDiscount.status')}}</label>

                <div v-if="shipmentDiscount.status">
                  <fieldset class="form" v-show="!baseDataHidden">
                    <div style="min-width:0;text-align:center">
                      <label style="text-align:center" for="'shipment-discount-minShipmentCount'">{{ $t('orderSeasonForm.shipmentDiscount.minShipmentCount')}}</label>
                      <input id="'shipment-discount-minShipmentCount'" type="text"  style="width:4.5rem;text-align:center" v-model="shipmentDiscount.minShipmentCount">
                    </div>
                    <div style="min-width:0;text-align:center">
                      <label style="text-align:center" for="'shipment-discount-minAmountPerShipment'">{{ $t('orderSeasonForm.shipmentDiscount.minAmountPerShipment')}}</label>
                      <input id="'shipment-discount-minAmountPerShipment'"  style="width:6.5rem;text-align:center" type="number" value="0" min="0.00" max="10000.00" v-model="shipmentDiscount.minAmountPerShipment">
                    </div>
                    <div style="min-width:0;text-align:center">
                      <label style="text-align:center" for="'shipment-discount-percentage'">{{ $t('orderSeasonForm.shipmentDiscount.percentage')}}</label>
                      <input id="'shipment-discount-percentage'" style="width:4.5rem;text-align:center" type="text" v-model="shipmentDiscount.percentage">
                    </div>
                  </fieldset>
                </div>
              </div>
             <!--<div>
                <h3>{{ $t('orderSeasonForm.generalDiscountTitle') }}</h3>
                <base-discount v-model="baseDiscount"></base-discount>
                <volume-discount v-model="volumeDiscount"></volume-discount>
              </div>-->
            </fieldset>


            <order-sheet
                :editor="true"
               :class="{'article-selection':true, 'top-space':baseDataHidden}"
               ref="articles"
               single-selection
            ></order-sheet>


         <div class="order-season-action">
             <div class="customer-link" v-if="seasonId">
                 <label>{{ $t('orderSeasonForm.link') }}</label>
                 <input type="text" readonly v-model="orderSeasonLink">
             </div>
             <button class="primary" @click="saveSeason" v-if="isNew">
                 {{ $t('orderSeasonForm.save') }}
                 <loading-spinner v-if="saving"></loading-spinner>
                 <fa-icon v-else icon="save"></fa-icon>
             </button>
             <button class="primary" @click="updateSeason" v-else>
                 {{ $t('orderSeasonForm.update') }}
                 <loading-spinner v-if="saving"></loading-spinner>
                 <fa-icon v-else icon="save"></fa-icon>
             </button>
         </div>
     </div>
 </page-with-navigation>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import {de} from 'vuejs-datepicker/dist/locale';
import axios from "axios";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import EventBus from "@/FrontEnd/eventbus";
import DiscountScaleAmount from "@/FrontEnd/components/DiscountScaleAmount";
import DiscountScaleQuantity from "@/FrontEnd/components/DiscountScaleQuantity";
import AdvanceOrderDeadlineEditor from "@/FrontEnd/components/AdvanceOrderDeadlineEditor";
import BaseDiscount from "@/FrontEnd/components/BaseDiscount";
import VolumeDiscount from "@/FrontEnd/components/VolumeDiscount";
import DiscountShipmentCount from "@/FrontEnd/components/DiscountShipmentCount";

import DiscountShipment from "@/FrontEnd/models/DiscountShipment";
import {buildBrandIndex} from "@/FrontEnd/utils/brandAnchorManager";

export default {
 name: "OrderSeasonEditor",
 components: {
   // eslint-disable-next-line vue/no-unused-components
    DiscountShipmentCount,
   // eslint-disable-next-line vue/no-unused-components
    VolumeDiscount,
    AdvanceOrderDeadlineEditor,
    DiscountScaleQuantity,
    DiscountScaleAmount,
    BaseDiscount,
    OrderSheet,
    LoadingSpinner,
    PageWithNavigation,
    Datepicker
 },
 props: {
     seasonId: String
 },
 data () {
     let today = new Date();
     let in3Months = new Date();
     in3Months.setMonth(today.getMonth() + 3);

     return {
        name: '',
        startDate: today,
        endDate: in3Months,
        de,
        saving: false,
        nameMissing: false,
        volumeDiscount: null,
        baseDiscount: null,
        minOrderAmount:{
          perShipment: false,
          minAmount: 0
        },
        shipmentDiscount: {
          status: false,
          minShipmentCount: 0,
          minAmountPerShipment: 0,
          percentage:0
        },
        discountScale: [],
        baseDataHidden: false,
        usePeriods: true,
        allowDeadlineSelection: false,
        deadlines: [
          {date: new Date(), startDate: new Date(), endDate: new Date(in3Months)}
        ],
        discountType : "amount",
        orderMode: "singleOrder"
     }
 },
 created() {
     if (this.seasonId) {
       this.loadSeason();
     }
 },
 computed: {
     isNew() {
         return !this.seasonId;
     },
     orderSeasonLink() {
         return window.location.origin + `/advanceOrder/${this.seasonId}`;
     }
 },
 watch: {
   /*
     usePeriods(newVal) {

         if (newVal === true && this.deadlines.length < 5) {
             this.deadlines.push({date: new Date(), startDate: new Date(), endDate: new Date(this.endDate)});
         }
         if (newVal === false && this.deadlines.length > 4) {
             this.deadlines.pop();
         }

     }*/
 },
 methods: {


   hideHiddenAmountHint() {
     this.hiddenAmount = false;
   },
     loadSeason() {
         let sToken = this.$store.state.sessionToken;
         axios.get(`/api/orderSeason/${this.seasonId}?session=${sToken}`)
             .then(response => {

                 this.name = response.data.name;
                 let selection = this.$refs.articles.getShipments().getFirst();
                 for (let brand of response.data.articles) {
                     for (let article of brand.articles) {
                         selection.setAmount(article.id, 1);
                         selection.setVEUsage(article.id,article.usesStaggeredQuantity);
                     }
                 }



                   this.endDate = Date.parse(response.data.endDate);

                   this.startDate = Date.parse(response.data.startDate);

                   this.allowDeadlineSelection = response.data.allowDeadlineSelection;
                   this.usePeriods = response.data.usePeriods;
                   this.orderMode = response.data.orderMode;
                   this.deadlines = response.data.deadlines.map(
                       (deadline) => {
                         deadline.date = Date.parse(deadline.date);
                         deadline.startDate = Date.parse(deadline.startDate);
                         deadline.endDate = Date.parse(deadline.endDate) || new Date(this.endDate);
                         return deadline;
                       }
                   );
                   this.baseDiscount = response.data.baseDiscount;
                   this.discountScale = response.data.staggeredDiscounts;
                   this.shipmentDiscount = response.data.shipmentDiscount;
                   this.minOrderAmount = response.data.minOrderAmount;
                   if (response.data.volumeDiscounts && response.data.volumeDiscounts.length > 0) {
                     this.volumeDiscount = true;
                   }
                  this.brands = response.data.articles;
                  buildBrandIndex(this.brands, true);


             })
             .catch(error => {
                 if (error.response && error.response.status === 401) {
                     this.$store.dispatch('logout', {name: this.$route.name});
                 }
             })
             .finally(() => {
                 this.loading = false;
             });
     },
     saveSeason() {
         if (!this.validate()) {
             return;
         }
         let sToken = this.$store.state.sessionToken;
         this.saving = true;
         axios.post(`/api/orderSeason?session=${sToken}`, this.preparePayload())
             .then((response) => {
                 EventBus.$emit(
                     'modal-requested',
                     this.$t('orderSeasonForm.saveSuccess'),
                     () => {
                         this.$router.push({ name: 'edit-season', params: {seasonId: response.data.id}});
                     }
                 );
             })
             .catch(error => {
                 if (error.response && error.response.status === 401) {
                     this.$store.dispatch('logout', {name: this.$route.name});
                     return;
                 }
                 EventBus.$emit(
                     'modal-requested',
                     this.$t('orderRecForm.unknownError')
                 );
             })
             .finally(() => {
                 this.saving = false;
             });
     },
     updateSeason() {
         if (!this.validate()) {
             return;
         }

         let sToken = this.$store.state.sessionToken;
         this.saving = true;
         axios.post(`/api/orderSeason/${this.seasonId}?session=${sToken}`, this.preparePayload())
             .then(() => {
                 EventBus.$emit(
                     'modal-requested',
                     this.$t('orderSeasonForm.updateSuccess')
                 );
             })
             .catch(error => {
                 if (error.response && error.response.status === 401) {
                     this.$store.dispatch('logout', {name: this.$route.name});
                     return;
                 }
                 EventBus.$emit(
                     'modal-requested',
                     this.$t('orderRecForm.unknownError')
                 );
             })
             .finally(() => {
                 this.saving = false;
             });
     },
     preparePayload() {
         let deadlines = JSON.parse(JSON.stringify(this.deadlines));
         deadlines.map((deadline) => {
             deadline.date = (
                 (deadline.date instanceof Date) ? deadline.date : new Date(deadline.date)
             ).toISOString();
             deadline.startDate = (
                 (deadline.startDate instanceof Date) ? deadline.startDate : new Date(deadline.startDate)
             ).toISOString();
             deadline.endDate = (
                 (deadline.endDate instanceof Date) ? deadline.endDate : new Date(deadline.endDate)
             ).toISOString();
             return deadline;
         });

         let articles = this.$refs.articles.getSelectedArticles();
         let articleOptions = this.$refs.articles.getShipments().getFirst().getArticlesWithOptions();



          return {
            name: this.name,
            deadlines,
            startDate: (this.startDate instanceof Date) ? this.startDate.toISOString() : new Date(this.startDate).toISOString(),
            endDate: (this.endDate instanceof Date) ? this.endDate.toISOString() : new Date(this.endDate).toISOString(),
            articles: articles,
            options: articleOptions,
            volumeDiscounts: this.volumeDiscount,
            shipmentDiscount: this.shipmentDiscount,
            minOrderAmount: this.minOrderAmount,
            baseDiscount: this.baseDiscount,
            staggeredDiscounts: this.discountScale,
            allowDeadlineSelection: this.allowDeadlineSelection,
            usePeriods: this.usePeriods,
            discountType: this.discountType,
            orderMode : this.orderMode

         }
     },
     validate() {
         this.nameMissing = false;
         if (!this.name) {
             this.nameMissing = true;
             return false;
         }
         if (this.$refs.articles.getSelectedArticles().length < 1) {
             EventBus.$emit(
                 'modal-requested',
                 this.$t('orderSeasonForm.articlesMissing')
             );
             return false;
         }
         return true;
     },
     toggleBaseData() {
         this.baseDataHidden = !this.baseDataHidden;
     },
     formatDate(date) {
         return this.$d(date, 'short');
     }
 }
}
</script>

<style scoped lang="scss">
 @import "@/FrontEnd/styles/input";
 @import "@/FrontEnd/styles/layout";

 ::v-deep input[type=text] {
     width: 100%;
     @include input-style;
 }


 .top-space {
     margin-top: 4rem;
 }

 .article-selection {
     flex: 1;
     overflow-y: scroll;
     padding: 0 .4rem;
 }

 .saveButton{
   padding: $button-padding;
   border-radius: unset;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   font-size: 1rem;
   display: inline-block;
   position: relative;
   font-weight: bold;
   text-decoration: none;
   text-align: left;
   cursor: pointer;
   border: $button-border;
   margin-right: .3125rem;
   background: $brand_color;
   line-height: 2.25rem;
   height: 2.625rem;
   color: white;
 }
 .form-wrapper {
     @include content-wrapper-inside;
     position: relative;
     &>button {
         position: absolute;
         top: 0;
         right: 0;
         margin: $regular-padding;
     }
 }
 .order-season-discounts, .order-season-periods {
     border: $content-box-border;
     box-sizing: border-box;
     width: 100%;
     max-width: 60rem;
     margin: 1.5rem auto $regular-padding;
     padding: $regular-padding;
     position: relative;
     &>* {
         margin-bottom: $regular-padding;
     }
     &>h4 {
         color: $brand-color;
         font-weight: bold;
         text-align: left;
         position: absolute;
         display: block;
         height: 2rem;
         top: -3.4rem;
         left: -.1rem;
         box-sizing: border-box;
         padding: .5rem;
         background: $brand-contrast-color;
         border: $content-box-border;
         border-bottom: none;
     }
 }

 .order-season-modes {
   border: $content-box-border;
   box-sizing: border-box;
   width: 100%;
   max-width: 60rem;
   margin: 1.5rem auto $regular-padding;
   padding: $regular-padding;
   position: relative;
   &>* {
     margin-bottom: $regular-padding;
   }
   &>h4 {
     color: $brand-color;
     font-weight: bold;
     text-align: left;
     position: absolute;
     display: block;
     height: 2rem;
     top: -3.4rem;
     left: -.1rem;
     box-sizing: border-box;
     padding: .5rem;
     background: $brand-contrast-color;
     border: $content-box-border;
     border-bottom: none;
   }
 }


 .order-season-action {
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
     &>* {
         margin: 0 $regular-padding;
     }
     @include footer-action-bar;
 }
 .customer-link {
     width: 50%;
     max-width: 40rem;
     display: flex;
     flex-direction: row;
     align-items: center;
 }

 .box-title{
   margin-top: 0rem;
    float:left;
   font-family:'Open Sans',sans-serif;
   color:#aaa;
 }

 .date {
   min-width: 9rem;

   & > span {
     display: inline-block;
     width: 100%;

     & .shipment-date ::v-deep input {
       @include input-style;
       width: 100%;
     }
   }
 }
 .visibility-toggle {
   font-size: 0.8rem;
   color: white;
   border:none;
   box-sizing: border-box;
   border-radius: 100px;
   background-image: linear-gradient(#565656 0%, #444 69%);
   position: absolute;
   // right: $large-padding;
 }





</style>