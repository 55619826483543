<template>
    <page-with-navigation>
        <div class="order-rec-form">
            <button v-if="!confirmMode" class="visibility-toggle slim" @click="toggleBaseData">
                <span v-if="baseDataHidden">{{ $t('orderSeasonForm.show') }}</span>
                <span v-else>{{ $t('orderSeasonForm.hide') }}</span>
            </button>
            <h2 v-if="!confirmMode">{{ $t('orderRecForm.title') }}</h2>
            <h2 v-else>{{ $t('orderRecForm.confirmTitle') }}</h2>
            <fieldset class="form" v-show="!baseDataHidden">
                <div :class="{'error-input':nameMissing}">
                    <label :for="'name-'+_uid">{{ $t('orderRecForm.name') }}</label>
                    <input :id="'name-'+_uid" type="text" name="offerName" v-model="offerName"/>
                    <span v-if="nameMissing" class="error-msg">{{ $t('orderRecForm.nameMissing') }}</span>
                </div>

                <div v-if="confirmMode" :class="{'error-input':customerMissing}">
                    <label :for="'customer-'+_uid">{{ $t('orderRecForm.customer') }}</label>
                    <customer-selector :id="'customer-'+_uid" v-model="customerId"></customer-selector>
                    <span v-if="customerMissing" class="error-msg">{{ $t('orderRecForm.customerMissing') }}</span>
                </div>

                <div v-if="confirmMode">
                    <label :for="'valid-until-'+_uid">{{ $t('orderRecForm.validUntil') }}</label>
                    <datepicker
                        :id="'valid-until-'+_uid"
                        :format="formatDate"
                        :language="de"
                        v-model="validUntil"
                        monday-first
                    ></datepicker>
                </div>

                <div class="full-width">
                    <label :for="'mesasge-'+_uid">{{ $t('orderRecForm.message') }}</label>
                    <textarea :id="'message-'+_uid" v-model="offerMessage"></textarea>
                </div>
            </fieldset>

            <fieldset class="order-sheet-tools" v-if="!confirmMode">
                <div class="brand-nav">
                    <brand-navigation :brands="articles"></brand-navigation>
                </div>
                <div>
                    <input v-model="hideEmptyPos" :id="'hide-empty-pos-'+_uid" type="checkbox" />
                    <label :for="'hide-empty-pos-'+_uid">{{ $t('orderSheet.hideEmptyPos') }}</label>
                </div>
                <filter-input :placeholder="$t('orderSheet.search')" v-model="articleSearch"></filter-input>
            </fieldset>

            <order-sheet
                class="order-rec-sheet"
                ref="order"
                :hide-empty-positions="hideEmptyPos"
                :article-search-filter="articleSearch"
                :disabled="confirmMode"
                :use-presale-price="false"
                @reset-filter-and-show-amounts="resetFilterAndShowAmounts"
            ></order-sheet>

            <div class="order-rec-footer">
                <button @click="showOverview" v-if="!confirmMode">
                    <fa-icon icon="arrow-left"></fa-icon>
                    {{ $t('orderRecForm.back') }}
                </button>
                <pdf-export
                    v-if="!confirmMode"
                    :get-payload="preparePayload"
                    target="/api/orderRecommendation/export"
                    :request-price-group="true"
                    :file-name="offerName"
                ></pdf-export>
                <button @click="confirmRecommendation" v-if="!confirmMode">
                    {{ $t('orderRecForm.confirm') }}
                    <fa-icon icon="share"></fa-icon>
                </button>
                <button class="primary" @click="updateRecommendation" v-if="offerId && !confirmMode">
                    {{ $t('orderRecForm.update') }}
                    <loading-spinner v-if="saving"></loading-spinner>
                    <fa-icon v-else icon="save"></fa-icon>
                </button>
                <button class="primary" @click="saveRecommendation" v-if="!offerId && !confirmMode">
                    {{ $t('orderRecForm.save') }}
                    <loading-spinner v-if="saving"></loading-spinner>
                    <fa-icon v-else icon="save"></fa-icon>
                </button>
                <button @click="cancelConfirmation" v-if="confirmMode">
                    {{ $t('orderRecForm.cancel') }}
                </button>
                <button class="primary" @click="sendRecommendation" v-if="confirmMode">
                    {{ $t('orderRecForm.send') }}
                    <loading-spinner v-if="sending"></loading-spinner>
                    <fa-icon v-else icon="share"></fa-icon>
                </button>
            </div>
        </div>
    </page-with-navigation>
</template>

<script>
import CustomerSelector from "@/FrontEnd/components/CustomerSelector";
import OrderSheet from "@/FrontEnd/components/OrderSheet";
import axios from "axios";
import PageWithNavigation from "@/FrontEnd/components/PageWithNavigation";
import LoadingSpinner from "@/FrontEnd/components/LoadingSpinner";
import PdfExport from "@/FrontEnd/components/PdfExport";
import FilterInput from "@/FrontEnd/components/FilterInput";
import EventBus from "@/FrontEnd/eventbus";
import BrandNavigation from "@/FrontEnd/components/BrandNavigation";
import Datepicker from "vuejs-datepicker";
import {de} from 'vuejs-datepicker/dist/locale';

export default {
name: "OrderRecommendationEditor",
    components: {
        BrandNavigation,
        FilterInput, PdfExport, LoadingSpinner, PageWithNavigation, OrderSheet, CustomerSelector, Datepicker},
    data() {
        return {
            customerId: null,
            offerName: '',
            offerMessage: '',
            saving: false,
            sending: false,
            customerMissing: false,
            nameMissing: false,
            noAmount: false,
            hideEmptyPos: false,
            articleSearch: '',
            confirmMode: false,
            baseDataHidden: false,
            validUntil: null,
            articles: [],
            de
        }
    },
    props: {
        offerId: {
            type: String
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNavigation);
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.preventNavigation);
    },
    created() {
        let sToken = this.$store.state.sessionToken;
        if (this.offerId) {
            axios.get(`/api/orderRecommendation/${this.offerId}?session=${sToken}`)
                .then(response => {
                    this.offerName = response.data.name;
                    this.offerMessage = response.data.message;
                    let shipment = this.$refs.order.getShipments().getFirst();
                    if (response.data.articleAmounts) {
                        for (let [articleId, amount] of Object.entries(response.data.articleAmounts)) {
                            shipment.setAmount(articleId, amount);
                        }
                    }
                    this.hideEmptyPos = true;
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                    }
                });
        }
        axios.get(`/api/articlesByBrand?session=${sToken}`)
            .then(response => {
                this.articles = response.data;
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    this.$store.dispatch('logout', {name: this.$route.name});
                }
            });
    },
    watch: {
        customer(newVal) {
            if (newVal !== null) {
                this.customerMissing = false;
            }
        }
    },
    methods: {
        preventNavigation(event) {
            if (!this.saving && !this.sending) return;
            event.preventDefault();
            event.returnValue = "";
        },
        preparePayload() {
            let shipment = this.$refs.order.getShipments().getFirst();
            return {
                customerId: this.customerId,
                name: this.offerName,
                message: this.offerMessage,
                articleAmounts: shipment.articleAmounts,
                templateId: this.offerId ? this.offerId : null,
                validUntil: this.validUntil !== null ? this.validUntil.toISOString() : null
            };
        },
        saveRecommendation() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.post(`/api/orderRecommendation?session=${sToken}`, this.preparePayload())
                .then(() => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.saveSuccess')
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {
                            name: this.$route.name
                        });
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        updateRecommendation() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.saving = true;
            axios.post(`/api/orderRecommendation/${this.offerId}?session=${sToken}`, this.preparePayload())
                .then(() => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.updateSuccess')
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        sendRecommendation() {
            if (!this.validate()) {
                return;
            }
            let sToken = this.$store.state.sessionToken;
            this.sending = true;
            axios.post(`/api/orderRecommendation/send?session=${sToken}`, this.preparePayload())
                .then(() => {
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.sendSuccess')
                    );
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        this.$store.dispatch('logout', {name: this.$route.name});
                        return;
                    }
                    EventBus.$emit(
                        'modal-requested',
                        this.$t('orderRecForm.unknownError')
                    );
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        confirmRecommendation() {
            this.confirmMode = true;
            this.articleSearch = '';
            this.hideEmptyPos = true;
        },
        showOverview() {
            this.$router.push({name: 'list-recommendations'});
        },
        cancelConfirmation() {
            this.confirmMode = false;
        },
        validate() {
            this.customerMissing = false;
            this.nameMissing = false;
            if (this.confirmMode) {
                if (this.customerId == null) {
                    this.customerMissing = true;
                }
            }
            if (!this.offerName) {
                this.nameMissing = true;
            }
            if(!this.$refs.order.hasAnyAmount()) {
                this.noAmount = true;
            }
            if (this.nameMissing || this.customerMissing || this.noAmount) {
                return false;
            }
            return true;
        },
        resetFilterAndShowAmounts() {
            this.hideEmptyPos = true;
            this.articleSearch = '';
        },
        toggleBaseData () {
            this.baseDataHidden = !this.baseDataHidden;
        },
        formatDate(date) {
            return this.$d(date, 'short');
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/FrontEnd/styles/input";
    @import "@/FrontEnd/styles/layout";

    .order-rec-form {
        @include content-wrapper-inside;
    }

    .order-rec-sheet {
        flex: 1;
        overflow-y: scroll;
        padding: 0 .4rem;
    }
    .order-rec-footer {
        @include footer-action-bar;
    }
    .order-sheet-tools {
        @include tool-bar;
        padding: $regular-padding $large-padding;

        & .brand-nav {
            margin-right: auto;
            margin-left: 0;
        }
    }


    ::v-deep .vdp-datepicker input {
        color:black;
        @include input-style;
    }

    .visibility-toggle {
        font-size: 0.8rem;
        border:none;
        box-sizing: border-box;
        border-radius: 100px;
        background-image: linear-gradient(#565656 0%, #444 69%);
        position: fixed;
       // right: $large-padding;
    }
</style>
